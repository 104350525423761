export const environment = {
  production: true,
  envName: 'prod',
  sbiUrl: 'https://www.sbicard.com',
  preLoginUrl: 'https://aurumcreditcard.com',
  apiUrl: 'https://rewardshub-api-customer.poshvine.com/api/v1/',
  stagingUrl: 'https://rewardshub-api-customer.poshvine.com/',
  giftCard: {
    apiUrl: 'https://offers-api-customer.poshvine.com/offer_service/internal/',
    apiUrlNew: 'https://offerservice.poshvine.com/offer_service/v2',

    clientToken: '12345',
  },
  apiUrlPsV1: 'https://api.poshvine.com/ps/v1/',
  v2ApiUrl: 'https://api.poshvine.com/cs/v1',
  v2ApiBs: 'https://api.poshvine.com/bs/v1',
  v2ApiBsInternal: 'https://api.poshvine.com/bs/internal',
  apiUrlPaV1: "https://api.poshvine.com/pa/v1/",
  apiUrlLsV1: "https://api.poshvine.com/ls/v1/",
  apiUrlPtsV1: "https://api-customer.poshvine.com/pts/v1/",
  clientId: 'de948494-88b0-4f29-a1ab-fc233e1c58f9',
  projectId: 'eb1d593c-2797-4c32-957c-90f2038c6da8',
  gmApiKey: 'AIzaSyAXREnbpzSGulf8xS5J43tsYwrbBFltR7k',
  SENTRY_ORG: 'rzp',
  SENTRY_PROJECT: 'sbi-aurum',
  SENTRY_AUTH_TOKEN: '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',
  sentryDsn: 'https://9d078d6f381c277a40cba1ba0b43bada@o515678.ingest.us.sentry.io/4507389415784448',
  ADANI_PROVIDER_ID_PX: 'af533152-70fb-4686-8cb8-4e9e5141324f',
};
