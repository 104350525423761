<div class="d-xl-none d-lg-none d-md-none d-flex align-items-center fnt-16 pad-top-10 pad-bot-10 relative">
  <div
    (click)="back()"
    class="d-flex justify-content-start align-items-center pointer mar-left-20 mar-right-20 absolute" style="left:5px;">
    <div class="mar-bot-3">
      <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.469669 5.46967C0.176777 5.76256 0.176777 6.23744 0.469669 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46447 6.59619 0.989593 6.3033 0.696699C6.01041 0.403806 5.53553 0.403806 5.24264 0.696699L0.469669 5.46967ZM17 5.25L1 5.25V6.75L17 6.75V5.25Z"
          fill="#C2A63F" />
      </svg>
    </div>
  </div>
  <span class="ft-wt-500 fnt-14 gold" style="margin: auto;">{{ providers?.name }}</span>
</div>
<hr class="d-xl-none d-lg-none d-md-none">
<div class="d-none d-sm-none d-md-block back-container back-btn-desktop">
  <div class="blueify mar-bot-10 d-flex justify-content-start align-items-center pointer" (click)="back()"
    style="width: max-content;">
    <div class="mar-bot-3">
      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.46967 5.46967C0.176777 5.76256 0.176777 6.23744 0.46967 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46447 6.59619 0.989593 6.3033 0.696699C6.01041 0.403806 5.53553 0.403806 5.24264 0.696699L0.46967 5.46967ZM13 5.25L1 5.25V6.75L13 6.75V5.25Z"
          fill="#C2A63F" />
      </svg>
    </div>
    <div class="mar-left-10 fnt-14 primary-color" (click)="back()">
      Back
    </div>
  </div>
</div>
<div
  *ngIf="providers"
  class="points-details-container w-100 pad-20-y pad-16-x d-md-flex justify-md-content-center px-md-0"
  [ngClass]="{'cursor-events-none':isPaymentUnderProcess}"
>
  <div class="co-12 col-lg-7 pad-md-20-x p-0">
    <div
      *ngIf="!selectedAccount"
      (click)="openAccountModal()"
      class="link-account-cta mar-md-40-b w-100 pad-20-y pad-16-x pad-md-24-x pad-md-28-y d-flex justify-content-start align-items-center pointer"
      [ngClass]="{'bring-div-to-focus': bringIntoFocus}"
    >
      <div
        class="img-container d-flex justify-content-center align-items-center"
      >
        <svg
          class=""
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
        >
          <rect width="40" height="40" rx="20" fill="#101010" />
          <path
            d="M12.1459 21.2368H18.7634V27.8542C18.7634 28.5268 19.3166 29.091 20.0001 29.091C20.6835 29.091 21.2368 28.5268 21.2368 27.8542V21.2368H27.8543C28.5269 21.2368 29.091 20.6835 29.091 20C29.091 19.3166 28.5269 18.7633 27.8543 18.7633H21.2368V12.1458C21.2368 11.4732 20.6835 10.9091 20.0001 10.9091C19.3166 10.9091 18.7634 11.4732 18.7634 12.1458V18.7633H12.1459C11.4733 18.7633 10.9092 19.3166 10.9092 20C10.9092 20.6835 11.4733 21.2368 12.1459 21.2368Z"
            fill="#C2A63F"
          />
        </svg>
      </div>
      <div class="text-container">
        <div class="text-black font-md-16px font-13px ft-wt-600">
          Link your {{ providers?.name }} account
        </div>
        <div class="text-black font-11px font-md-12px">
          <ng-container *ngIf="!isUserLoggedIn">
            Add your account details to unlock rewarding transfers
          </ng-container>
          <ng-container *ngIf="isUserLoggedIn">
            You're eligible to get up to
            <em class="ft-wt-600">
              {{ this.points / providers.conversion_ratio | currency : "" : "" : "1.0-0" }} {{providers?.currency_name}}
            </em>
          </ng-container>

        </div>
      </div>
    </div>

    <div
      *ngIf="selectedAccount"
      class="linked-account mar-md-40-b pad-20-y pad-16-x pad-md-24-x pad-md-28-y d-flex justify-content-between generic-border-class"
    >
      <div class="account">
        <div class="font-13px font-md-14px text-grey">
          Your Linked {{ providers?.name }} Account
        </div>
        <span class="font-14px font-md-16px"
          >{{ selectedAccount | accountIdDisplay : providers?.id }}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="12"
            viewBox="0 0 10 12"
            fill="none"
          >
            <path
              d="M4.55 0.0948997L0.661111 1.80585C0.261111 1.98189 0 2.37799 0 2.81261V5.39828C0 8.45158 2.13333 11.3068 5 12C7.86667 11.3068 10 8.45158 10 5.39828V2.81261C10 2.37799 9.73889 1.98189 9.33889 1.80585L5.45 0.0948997C5.16667 -0.0316332 4.83333 -0.0316332 4.55 0.0948997ZM3.49444 8.30854L2.05556 6.88367C1.83889 6.66911 1.83889 6.32252 2.05556 6.10797C2.27222 5.89341 2.62222 5.89341 2.83889 6.10797L3.88889 7.14223L7.15556 3.90739C7.37222 3.69284 7.72222 3.69284 7.93889 3.90739C8.15556 4.12195 8.15556 4.46854 7.93889 4.68309L4.27778 8.30854C4.06667 8.5231 3.71111 8.5231 3.49444 8.30854Z"
              fill="#008659"
            />
          </svg>
        </span>
      </div>
      <div
        class="actions text-brand font-12px font-md-14px ft-wt-600 pointer"
        (click)="openAccountModal()"
      >
        Add/Edit
      </div>
    </div>

    <div (click)="onTransferButtonClick()" class="sub-text mar-16-b mar-md-20-b font-13px font-md-16px ft-wt-600">
      Enter points to transfer
    </div>
    <ng-template #pointsConversionPanel>
      <div class="mar-bot-12">
        <span class="burn-ratio text-dark-grey"
          >{{ providers.conversion_ratio }}
          {{ appConstant.REWARD_POINTS_PROGRAM_NAME }} =
        </span>
        <span class="text-grey-light">1 {{ providers?.currency_name }}</span>
      </div>
      <div class="">
        <span class="transfer text-dark-grey">Approximate transfer time : </span>
        <span class="text-grey-light text-capitalize">{{
          providers.transfer_type ?? "N.A."
        }}</span>
      </div>
    </ng-template>
    <div class="exchange-container generic-border-class pad-md-24-x">
      <app-points-input-box
        [merchantText]="appConstant.REWARD_POINTS_PROGRAM_NAME"
        [rewardPoints]="conversionObject.bankPoints"
        [userRewardPoints]="points"
        (pointsChangeEvent)="handlePointsChangeEvent($event)"
        [enableTransferArrow]="true"
        [minimumValue]="MIN_POINTS_REDEMPTION"
        [maximumValue]="MAX_SATISFACTORY_POINTS"
        [customError]="customError"
        [merchantImg]="'./assets/sbic-images/logo-black-bg.svg'"
      ></app-points-input-box>
      <app-points-input-box
        [merchantText]="providers?.currency_name"
        [rewardPoints]="conversionObject.providerPoints"
        [isReadOnly]="true"
        [errorMessagesEnabled]="false"
        [merchantImg]="providers?.logo"
      ></app-points-input-box>
      <div
        class="points-conversion d-flex d-md-none flex-row pad-16-y pad-16-x pad-md-24-x pad-md-0-x font-12px"
      >
        <div class="cont w-md-50 mr-1">
          <ng-container
            [ngTemplateOutlet]="pointsConversionPanel"
          ></ng-container>
        </div>
      </div>
      <div
        *ngIf="selectedAccount"
        class="points-conversion d-none d-md-flex flex-row pad-16-y pad-16-x pad-md-24-x pad-md-0-x font-12px"
      >
        <div class="cont w-md-50 mr-1">
          <ng-container
            [ngTemplateOutlet]="pointsConversionPanel"
          ></ng-container>
        </div>
        <div
          class="cont d-flex w-50 min-width-50 ml-auto justify-content-end"
        >
          <button
            mat-button
            class="mat-focus-indicator w100 mat-button mat-button-base font-14px h-100 max-height-48px max-width-280px btn-light"
            [ngClass]="{ 'btn-disable': isButtonDisabled }"
            (click)="onTransferButtonClick()"
          >
          <div class="d-flex align-items-center justify-content-center mr-5 w-100">
            <ng-container *ngIf="!isPaymentUnderProcess">
              Transfer Points
            </ng-container>
            <span class="loader" *ngIf="isPaymentUnderProcess"></span>
          </div>

          </button>


        </div>
      </div>
    </div>
    <div
      *ngIf="!selectedAccount"
      class="d-none d-md-flex pad-md-24-x font-12px no-padding-right w-100"
    >
      <div class="cont w-50">
        <ng-container [ngTemplateOutlet]="pointsConversionPanel"></ng-container>
      </div>
      <div class="cont w-50 ml-auto d-flex justify-content-end cursor-events-none">
        <button (click)="focusLink()"
          mat-button
          class="mat-focus-indicator w100 mat-button mat-button-base font-14px h-100 max-height-48px max-width-280px btn-light btn-disable"
        >
          Link account to transfer
        </button>
      </div>
    </div>
  </div>

  <div class="co-12 col-lg-5 pad-md-20-x p-0">
    <div class="sub-text mar-16-b mar-md-20-b font-13px font-md-16px ft-wt-600">
      About {{ providers?.name }}
    </div>
    <div
      class="about-merchant pad-16-y pad-16-x mar-md-40-b font-13px font-md-14px generic-border-class font-12px text-dark-grey"
    >
      <app-read-more
        *ngIf="providers.description"
        description="{{ providers.description }}"
      ></app-read-more>
    </div>

    <div
      class="sub-text mar-16-b mar-md-20-b font-13px font-md-16px ft-wt-600"
    >
      Terms & Conditions
    </div>

    <div
      class="terms-and-conditions pad-16-y pad-16-x mar-md-40-b generic-border-class font-12px font-md-14px text-dark-grey"
    >
    <app-read-more
    *ngIf="providers.description"
    description="{{providers.tnc}}"
  ></app-read-more></div>
  </div>

  <div class="btn-container pad-16-x">
    <button
      mat-button
      class="mat-focus-indicator w100 mat-button mat-button-base font-14px"
      [disabled]="isButtonDisabled"
      [ngClass]="{
        'btn-disable': !selectedAccount || isButtonDisabled
      }"
      (click)="selectedAccount ? onTransferButtonClick() : focusLink()"
    >

      <div class="d-flex align-items-center justify-content-center mr-5 w-100">
        <ng-container *ngIf="!isPaymentUnderProcess">
          {{
            selectedAccount
              ? "Transfer Points"
              : "Link account to transfer"
          }}
        </ng-container>
        <span class="loader" *ngIf="isPaymentUnderProcess"></span>
      </div>
    </button>
  </div>
</div>

<app-loader *ngIf="isResponseLoading"></app-loader>
