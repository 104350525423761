<div
  *ngIf="!data.accounts?.length || showForm"
  class="modal-card d-flex flex-column justify-content-start align-items-center"
>
  <div
    *ngIf="showForm"
    class="back-btn d-flex justify-content-start align-center w-100 pointer"
    (click)="toggleForm()"
  >
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.92259 5.58925C9.24803 5.26382 9.24803 4.73618 8.92259 4.41074C8.59715 4.0853 8.06951 4.0853 7.74408 4.41074L2.74408 9.41074C2.41864 9.73618 2.41864 10.2638 2.74408 10.5893L7.74408 15.5893C8.06951 15.9147 8.59715 15.9147 8.92259 15.5893C9.24803 15.2638 9.24803 14.7362 8.92259 14.4107L5.34518 10.8333H16.6667C17.1269 10.8333 17.5 10.4602 17.5 10C17.5 9.53976 17.1269 9.16666 16.6667 9.16666H5.34518L8.92259 5.58925Z"
        fill="#C2A63F"
      />
    </svg>
  </div>
  <img
    class="logo"
    [src]="data.logo || appConstants.FALLBACK_IMAGE"
    alt="Provider_logo"
  />

  <div class="title-text mar-top-16 mar-bot-20">
    Enter your {{ data?.name }} account details to link your account
  </div>
  <form
    class="d-flex flex-column"
    [formGroup]="accountDetails"
    (ngSubmit)="saveAccount()"
  >
    <div class="input-sec w-100" *ngIf="!isAdani">
      <input
        formControlName="account_id"
        type="text"
        class="acc-input"
        [placeholder]="data?.name + ' ID'"
        [readonly]="isValidated || isLoading"
        [ngClass]="{
          'is-invalid': isSubmitted && accountDetails.controls.account_id.errors
        }"
      />
      <ng-container *ngTemplateOutlet="accountVerifiedBadge"></ng-container>
      <small
        *ngIf="
          isSubmitted && accountDetails?.controls?.account_id?.errors?.required
        "
        class="error-msg"
      >
        Please enter valid {{ data?.name }}'s membership account
      </small>
      <ng-template *ngTemplateOutlet="accountVerificationFail"></ng-template>
    </div>
    <div class="input-sec w-100">
      <input
        formControlName="name"
        type="text"
        class="acc-input"
        [placeholder]="'Member Full Name'"
        [readonly]="isValidated || isLoading"
        [ngClass]="{
          'is-invalid': isSubmitted && accountDetails.controls.name.errors
        }"
      />
      <small
        *ngIf="isSubmitted && accountDetails?.controls?.name?.errors?.required"
        class="error-msg"
      >
        Full name is required.
      </small>
    </div>
    <div class="input-sec w-100">
      <input
        formControlName="mobile"
        type="number"
        class="acc-input"
        [placeholder]="'Member Mobile number'"
        [readonly]="isValidated || isLoading"
        [ngClass]="{
          'is-invalid': isSubmitted && accountDetails.controls.mobile.errors
        }"
      />
      <ng-container *ngTemplateOutlet="isAdani && accountVerifiedBadge"></ng-container>
      <small
        *ngIf="
          isSubmitted && accountDetails?.controls?.mobile?.errors?.required
        "
        class="error-msg"
      >
        Mobile number is required.
      </small>
      <small
        *ngIf="
          isSubmitted && accountDetails?.controls?.mobile?.errors?.minLength
        "
        class="error-msg"
      >
        Mobile Number is invalid
      </small>
      <ng-template *ngTemplateOutlet="isAdani && accountVerificationFail"></ng-template>
    </div>
    <div class="input-sec w-100">
      <input
        formControlName="email"
        type="text"
        class="acc-input"
        [placeholder]="'Member Email'"
        [readonly]="isValidated || isLoading"
        [ngClass]="{
          'is-invalid': isSubmitted && accountDetails.controls.email.errors
        }"
      />
      <small
        *ngIf="isSubmitted && accountDetails?.controls?.email?.errors?.required"
        class="error-msg"
      >
        Email is required.
      </small>
      <small
        *ngIf="
          isSubmitted &&
          (accountDetails?.controls?.email?.errors?.email ||
            accountDetails?.controls?.email?.errors?.pattern)
        "
        class="error-msg"
      >
        Invalid Email Address
      </small>
    </div>
    <span class="membership-text"
      >All details must match with the data recorded in the
      {{ data?.name }} account mentioned.</span
    >
  </form>

  <div
    class="cta-section d-flex flex-column justify-content-start align-items-center"
  >
    <!-- <div class="d-flex justify-content-center align-items-center">
      <span class="link-text"> Not a member? &nbsp;</span>
      <a class="link" href="#">Join now!</a>
    </div> -->
    <button
      class="cta-btn"
      (click)="isValidated ? saveAccount() : validateAccount()"
    >
      <span *ngIf="!isLoading">{{ isValidated ? "Save" : "Continue" }}</span>
      <svg
        *ngIf="isLoading"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          opacity="0.3"
          cx="12"
          cy="12"
          r="10"
          stroke="white"
          stroke-width="4"
        />
        <path
          d="M16.5533 21.4277C16.9209 22.1887 17.8427 22.5156 18.5508 22.0542C20.3914 20.855 21.8763 19.1673 22.8306 17.1669C24.0057 14.7039 24.3061 11.914 23.6824 9.25728C23.0586 6.60058 21.548 4.23583 19.3997 2.55306C17.6549 1.18637 15.5741 0.33583 13.3921 0.0809972C12.5526 -0.0170442 11.8726 0.685868 11.8821 1.53099C11.8916 2.37611 12.5892 3.04012 13.4236 3.17472C14.9076 3.41412 16.316 4.02546 17.5124 4.96254C19.1128 6.21611 20.2381 7.97773 20.7027 9.95682C21.1674 11.9359 20.9436 14.0143 20.0682 15.8491C19.4139 17.2206 18.4247 18.3949 17.2023 19.2697C16.515 19.7615 16.1858 20.6666 16.5533 21.4277Z"
          fill="white"
        />
      </svg>
    </button>
  </div>
</div>

<div
  *ngIf="data.accounts?.length && !showForm"
  class="modal-card d-flex flex-column justify-content-start align-items-center"
>
  <img
    class="logo"
    [src]="data.logo || appConstants.FALLBACK_IMAGE"
    alt="Provider_logo"
  />

  <div class="title-text mar-top-16 mar-bot-20">
    <span *ngIf="!isUnlinkClicked; else unlinkText">
      Choose your {{ data?.name }}’s membership account
    </span>
    <ng-template #unlinkText>
      {{
        accountToUnlink.id == selected_account.value
          ? "Are you sure you want to unlink this " +
            data?.name +
            " account? This action will automatically switch/delete your account."
          : "Are you sure you want to unlink this " + data?.name + " account? "
      }}
    </ng-template>
  </div>

  <form *ngIf="!isUnlinkClicked" class="w-100 form">
    <ng-container *ngFor="let account of data.accounts">
      <div
        class="radio-row container pad-top-16 pad-bot-16 d-flex justify-content-between align-items-center"
      >
        <input
          type="radio"
          [value]="account.id"
          [formControl]="selected_account"
        />
        <span class="checkmark" (click)="onClickAccount(account.id)"></span>
        <span class="label d-flex justify-content-start align-items-center">
          <span>
            {{ account.attributes | accountIdDisplay : data?.id }}
          </span>
          <span *ngIf="account.attributes?.preferred" class="recent-tag mar-left-4"
            >Recently Used</span
          >
        </span>

        <svg
          (click)="onUnlinkClick(account)"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.33331 5.83333H16.6666M4.16665 5.83333L4.99998 15.8333C4.99998 16.2754 5.17557 16.6993 5.48813 17.0118C5.8007 17.3244 6.22462 17.5 6.66665 17.5H13.3333C13.7753 17.5 14.1993 17.3244 14.5118 17.0118C14.8244 16.6993 15 16.2754 15 15.8333L15.8333 5.83333M7.49998 5.83333V3.33333C7.49998 3.11232 7.58778 2.90036 7.74406 2.74408C7.90034 2.5878 8.1123 2.5 8.33331 2.5H11.6666C11.8877 2.5 12.0996 2.5878 12.2559 2.74408C12.4122 2.90036 12.5 3.11232 12.5 3.33333V5.83333M8.33331 10L11.6666 13.3333M11.6666 10L8.33331 13.3333"
            stroke="#C2A63F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </ng-container>
  </form>
  <!--
  <span class="error-msg" *ngIf="isSubmitted && account_id.errors?.['required']"
    >Please enter valid Club Vistara’s membership account</span
  >
  <span class="error-msg" *ngIf="isAPIcalled && !isLoading && !isValidated"
    >Sorry, we couldn't verify your account right now. Please try again
    later</span
  > -->
  <div
    *ngIf="!isUnlinkClicked; else unlinkCTA"
    class="cta-section d-flex flex-column justify-content-start align-items-center"
  >
    <button
      class="link-acc-btn d-flex justify-content-center align-items-center"
    >
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.16669 3.33332C9.16669 2.96513 8.86821 2.66666 8.50002 2.66666C8.13183 2.66666 7.83335 2.96513 7.83335 3.33332V7.33332H3.83335C3.46516 7.33332 3.16669 7.6318 3.16669 7.99999C3.16669 8.36818 3.46516 8.66666 3.83335 8.66666H7.83335V12.6667C7.83335 13.0348 8.13183 13.3333 8.50002 13.3333C8.86821 13.3333 9.16669 13.0348 9.16669 12.6667V8.66666H13.1667C13.5349 8.66666 13.8334 8.36818 13.8334 7.99999C13.8334 7.6318 13.5349 7.33332 13.1667 7.33332H9.16669V3.33332Z"
          fill="#C2A63F"
        />
      </svg>
      <span class="mar-left-4" (click)="toggleForm()"> Link New Account </span>
    </button>
    <button class="cta-btn" (click)="selectAccount()">
      <span>Continue</span>
      <svg
        *ngIf="isLoading"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          opacity="0.3"
          cx="12"
          cy="12"
          r="10"
          stroke="white"
          stroke-width="4"
        />
        <path
          d="M16.5533 21.4277C16.9209 22.1887 17.8427 22.5156 18.5508 22.0542C20.3914 20.855 21.8763 19.1673 22.8306 17.1669C24.0057 14.7039 24.3061 11.914 23.6824 9.25728C23.0586 6.60058 21.548 4.23583 19.3997 2.55306C17.6549 1.18637 15.5741 0.33583 13.3921 0.0809972C12.5526 -0.0170442 11.8726 0.685868 11.8821 1.53099C11.8916 2.37611 12.5892 3.04012 13.4236 3.17472C14.9076 3.41412 16.316 4.02546 17.5124 4.96254C19.1128 6.21611 20.2381 7.97773 20.7027 9.95682C21.1674 11.9359 20.9436 14.0143 20.0682 15.8491C19.4139 17.2206 18.4247 18.3949 17.2023 19.2697C16.515 19.7615 16.1858 20.6666 16.5533 21.4277Z"
          fill="white"
        />
      </svg>
    </button>
  </div>

  <ng-template #unlinkCTA>
    <div
      class="cta-section d-flex flex-column justify-content-start align-items-center"
    >
      <button class="cta-btn" (click)="unlinkAcc()">
        <span *ngIf="!isLoading">{{
          accountToUnlink.id == selected_account.value
            ? "Yes, use another account"
            : "Yes, Unlink"
        }}</span>
        <svg
          *ngIf="isLoading"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            opacity="0.3"
            cx="12"
            cy="12"
            r="10"
            stroke="white"
            stroke-width="4"
          />
          <path
            d="M16.5533 21.4277C16.9209 22.1887 17.8427 22.5156 18.5508 22.0542C20.3914 20.855 21.8763 19.1673 22.8306 17.1669C24.0057 14.7039 24.3061 11.914 23.6824 9.25728C23.0586 6.60058 21.548 4.23583 19.3997 2.55306C17.6549 1.18637 15.5741 0.33583 13.3921 0.0809972C12.5526 -0.0170442 11.8726 0.685868 11.8821 1.53099C11.8916 2.37611 12.5892 3.04012 13.4236 3.17472C14.9076 3.41412 16.316 4.02546 17.5124 4.96254C19.1128 6.21611 20.2381 7.97773 20.7027 9.95682C21.1674 11.9359 20.9436 14.0143 20.0682 15.8491C19.4139 17.2206 18.4247 18.3949 17.2023 19.2697C16.515 19.7615 16.1858 20.6666 16.5533 21.4277Z"
            fill="white"
          />
        </svg>
      </button>
      <button class="cancel-btn" (click)="isUnlinkClicked = false">
        <span *ngIf="!isLoading">Cancel</span>
      </button>
    </div>
  </ng-template>
</div>

<ng-template #accountVerifiedBadge>
  <div class="badge-parent">
    <span class="badge" *ngIf="isValidated">
      Account verified
      <svg
        width="14"
        height="16"
        viewBox="0 0 14 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.37 0.126533L0.925556 2.40779C0.365556 2.64252 0 3.17066 0 3.75014V7.19771C0 11.2688 2.98667 15.0758 7 16C11.0133 15.0758 14 11.2688 14 7.19771V3.75014C14 3.17066 13.6344 2.64252 13.0744 2.40779L7.63 0.126533C7.23333 -0.0421776 6.76667 -0.0421776 6.37 0.126533ZM4.89222 11.0781L2.87778 9.17822C2.57444 8.89215 2.57444 8.43003 2.87778 8.14396C3.18111 7.85788 3.67111 7.85788 3.97444 8.14396L5.44444 9.52298L10.0178 5.20986C10.3211 4.92378 10.8111 4.92378 11.1144 5.20986C11.4178 5.49593 11.4178 5.95805 11.1144 6.24413L5.98889 11.0781C5.69333 11.3641 5.19556 11.3641 4.89222 11.0781Z"
          fill="#008659"
        />
      </svg>
    </span>
  </div>
</ng-template>

<ng-template #accountVerificationFail>
  <span
        class="error-msg"
        *ngIf="isSubmitted && isAPIcalled && !isLoading && !isValidated"
      >
        {{
          errorMsg ??
            "Sorry, we couldn't verify your account right now. Please try again later"
        }}
      </span>
</ng-template>
