import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserAccount } from './points-transfer-details/points-transfer-details.component';

@Pipe({
  name: 'accountIdDisplay',
})
export class AccountIdDisplayPipe implements PipeTransform {
  /** * Transforms the given PointsProviderAccount into a displayable account ID.
   *
   * @param value - The PointsProviderAccount object or undefined.
   * @param providerId - The provider ID to determine which field to display.
   * @returns The mobile number if the provider ID matches the special case; otherwise, the account ID.
   */
  public transform(
    value: UserAccount | undefined,
    provider_id: string
  ): string {
    if (!value) {
      return '';
    }
    return provider_id === environment.ADANI_PROVIDER_ID_PX
      ? value?.mobile
      : value?.account_id;
  }
}
