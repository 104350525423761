import { appConstants } from "src/app/app.constants";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { PointsTransferService } from "./../points-transfer.service";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-account-modal",
  templateUrl: "./account-modal.component.html",
  styleUrls: ["./account-modal.component.scss"],
})
export class AccountModalComponent implements OnInit, OnDestroy {
  @Input() data;
  public isValidated = false;
  public isSubmitted = false;
  public isLoading = false;
  public isAPIcalled = false;
  public showForm = false;
  public isUnlinkClicked = false;
  public accountToUnlink;
  public isAdani: boolean = false;

  public accountDetails: FormGroup;
  public selected_account = new FormControl("");
  public appConstants = appConstants;
  public errorMsg = "";
  private userDetails = JSON.parse(localStorage.getItem("login_details") ?? "");
  private formSubscription: Subscription;

  private emailPattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(
    private ptsService: PointsTransferService,
    private toastr: ToastrService,
    private activeModal: NgbActiveModal,
    private fb: FormBuilder,
  ) {}

  ngOnInit() {
    this.generateFormData();
    if (this.data?.accounts?.length && this.data.selectedAccount) {
      this.selected_account.setValue(this.data.selectedAccount.id);
      console.log(this.selected_account.value);
    }
    this.formSubscription = this.accountDetails?.valueChanges.subscribe(() => {
      this.isSubmitted = false;
    });
  }

  ngOnDestroy(): void {
    this.formSubscription.unsubscribe();
  }

  private generateFormData() {
    this.isAdani = this.data.id === environment.ADANI_PROVIDER_ID_PX;
    const formControls: { [key: string]: any } = {
      name: [this.userDetails?.full_name ?? null, Validators.required],
      mobile: [this.userDetails?.mobile ?? null, [Validators.required]],
      email: [
        this.userDetails?.email ?? null,
        [Validators.required, Validators.email, Validators.pattern(this.emailPattern)],
      ],
    };
    if (!this.isAdani) {
      formControls['account_id'] = [null, Validators.required];
    }
    this.accountDetails = this.fb.group(formControls);
  }

  public saveAccount() {
    this.isLoading = true;
    const formValue = {...this.accountDetails.value, mobile: this.accountDetails.value.mobile.toString()}
    this.ptsService
      .saveUserAccount(this.data?.id, formValue)
      .subscribe(
        (res: any) => {
          if (res?.data?.id) {
            this.activeModal.close(true);
            this.toastr.success("Account saved successfully!");
          }
          this.isLoading = false;
        },
        (err: any) => {
          console.log(err);
          this.toastr.error(err.error.msg);
          this.isLoading = false;
          this.activeModal.dismiss();
        }
      );
  }

  public validateAccount() {
    this.isSubmitted = true;
    if (this.accountDetails?.valid) {
      this.isLoading = true;
      this.isAPIcalled = true;
      const formValue = {...this.accountDetails.value, mobile: this.accountDetails.value.mobile.toString()}
            this.ptsService
        .validateUserAccount(this.data?.id, formValue)
        .subscribe(
          (res: any) => {
            console.log(res);
            if (res.valid) {
              this.isValidated = true;
              this.isSubmitted = false;
              this.saveAccount();
            } else {
              this.errorMsg = res.msg;
            }
            this.isLoading = false;
          },
          (err: any) => {
            this.toastr.error(err.error.msg);
            this.isLoading = false;
            this.activeModal.dismiss();
          }
        );
    }
  }

  public toggleForm() {
    this.showForm = !this.showForm;
  }

  public onUnlinkClick(account) {
    this.accountToUnlink = account;
    this.isUnlinkClicked = true;
  }

  public selectAccount() {
    this.activeModal.close(this.selected_account.value);
  }

  public unlinkAcc() {
    this.ptsService
      .unlinkUserAccount(this.data.id, this.accountToUnlink.id)
      .subscribe(
        (res: any) => {
          this.toastr.success(res.msg);
          this.activeModal.close(true);
        },
        (err: any) => {
          console.log(err.error.msg);
          this.activeModal.dismiss();
        }
      );
  }

  private checkMinLength(minLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control?.value;
      return value?.toString()?.length < minLength ? { minLength: true } : null;
    };
  }

  public onClickAccount(accId: string) {
    this.selected_account.setValue(accId);
  }
}
