import { ToastrService } from "ngx-toastr";
import { AccountModalComponent } from "./../account-modal/account-modal.component";

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from "@angular/core";
import { PointsTransferService } from "../points-transfer.service";
import { ActivatedRoute, Route, Router } from "@angular/router";
import { appConstants } from "src/app/app.constants";
import { DataService } from "src/app/services/common/data/data.service";
import { Subscription, forkJoin } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MatDialog } from "@angular/material/dialog";
import { SbicLoginComponent } from "src/app/shared/components/sbic-login/sbic-login.component";
import { LoginService } from "src/app/services/login/login.service";
import { RazorpayPgService } from "razorpay-pg";
import { PaymentService } from "src/app/shared/components/payment/payment.service";
export type ProvidersDetails = {
  id: string;
  name: string;
  code: string;
  description: string;
  tnc: string;
  currency_name: string;
  logo: string;
  conversion_ratio: number;
  transfer_type: "instant" | "batch";
};
export interface UserAccount {
  id: string;
  account_id: string;
  preferred: boolean;
  mobile: string;
}

@Component({
  selector: "app-points-transfer-details",
  templateUrl: "./points-transfer-details.component.html",
  styleUrls: ["./points-transfer-details.component.scss"],
})
export class PointsTransferDetailsComponent {
  providers: ProvidersDetails;
  appConstant = appConstants;
  MIN_POINTS_REDEMPTION = appConstants.POINTS_TRANSFER_MIN_REDEEMABLE_FACTOR;
  MAX_SATISFACTORY_POINTS = 0;
  pointsSubscription: Subscription = {} as Subscription;
  isResponseLoading: boolean = false;
  isPaymentUnderProcess: boolean = false;
  bringIntoFocus = false;
  points: number = 0;
  conversionObject = {
    bankPoints: 0,
    providerPoints: 0,
  };
  userAccounts;
  customError = "";
  selectedAccount: UserAccount;
  providerId: string;
  isButtonDisabled = false;
  Math = Math;
  paymentSubscription: Subscription = {} as Subscription;
  constructor(
    private ptsService: PointsTransferService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private data: DataService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private router: Router,
    private pgService: RazorpayPgService,
    private changeDetector: ChangeDetectorRef,
    private paymentService: PaymentService,
  ) {
    this.providerId = this.route.snapshot.paramMap.get("provider_id");
    if (this.providerId) {
      let pointsApi = this.paymentService.getRedemptionInquiry();
      let providersApi = this.ptsService.getProviders(this.providerId);
      this.isResponseLoading = true;
      forkJoin([pointsApi, providersApi]).subscribe(results => {
        this.isResponseLoading = false;
        const pointsData: any = results[0];
        const providersData = results[1];
        if (pointsData?.errors) {
          this.toastr.error("Something went wrong. Please try again later.");
          this.back();
        } else {
          this.points = pointsData.CurrentBalance ? parseInt(pointsData.CurrentBalance) : 0;
          this.data.changePointsData(this.points);  //invoke new Data
          this.checkPointsData();
        }
        this.checkProviderDetails(providersData);
      }, 
      (err: any) => {
        this.toastr.error("Something went wrong. Please try again later.");
        console.log('err', err);
        this.isResponseLoading = false;
        this.back();
      });
      this.fetchUserAccounts(this.providerId);
    }
    this.pgService.paymentClickedEvent.subscribe((isLoading: boolean) => {
      this.updateAndDetectChange(isLoading);
    });
  }
  private updateAndDetectChange(isPaymentInitiated: boolean): void {
    this.isPaymentUnderProcess = isPaymentInitiated;
    this.changeDetector.detectChanges();
  }
  private checkPointsData(): void {
    this.isButtonDisabled =
    this.points < this.MIN_POINTS_REDEMPTION;
    this.MAX_SATISFACTORY_POINTS = this.getMaxSatisfactoryPoints();
      this.handlePointsChangeEvent(this.points < 10000 ? this.points :
        this.MAX_SATISFACTORY_POINTS
        );
  }
  private checkProviderDetails(providersData: any): void {
    this.providers = providersData?.data?.attributes;
    if(this.providers.conversion_ratio){
      this.MIN_POINTS_REDEMPTION = this.providers.conversion_ratio * this.MIN_POINTS_REDEMPTION;
    }
    this.MAX_SATISFACTORY_POINTS = this.getMaxSatisfactoryPoints();
    this.handlePointsChangeEvent(this.points < 10000 ? this.points :
      this.MAX_SATISFACTORY_POINTS
      );
  }

  private getMaxSatisfactoryPoints(): number {
    const subtractionValue = (this.points % this.MIN_POINTS_REDEMPTION);
    return this.points - subtractionValue;
  }

  public focusLink():void{
    this.bringIntoFocus = true;
    setTimeout(()=> this.bringIntoFocus = false,1000)
  }

  private fetchUserAccounts(providerID: string): void {
    this.isResponseLoading = true;
    this.ptsService.fetchUserAccounts(providerID).subscribe(
      (res: any) => {
        this.userAccounts = res.data;
        const preferredAccount = this.userAccounts.find(
          (acc) => acc.attributes.preferred == true
        );
        this.selectedAccount = preferredAccount?.attributes?.preferred
          ? preferredAccount.attributes
          : this.userAccounts.length
          ? this.userAccounts[0]?.attributes
          : null;
        console.log(">>>>>>>>> selected account", this.selectedAccount);
        this.isResponseLoading = false;
      },
      (error: HttpErrorResponse) => {
        this.isResponseLoading = false;
        this.toastr.error("Something went wrong.");
      }
    );
  }

  public openAccountModal() {
    if (!this.isUserLoggedIn) {
      this.openLoginModal();
      return;
    }
    const modalRef = this.modalService.open(AccountModalComponent, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "account-modal",
      backdropClass: "account-modal-backdrop",
      centered: !this.isMobile(),
      // keyboard: false,
    });

    modalRef.result.then((result) => {
      if (result === true) this.fetchUserAccounts(this.providerId);
      else if (result)
        this.selectedAccount = this.userAccounts.find(
          (acc) => acc.id == result
        )?.attributes;
    });

    modalRef.componentInstance.data = {
      logo: this.providers.logo,
      name: this.providers.name,
      id: this.providers.id,
      accounts: this.userAccounts,
      selectedAccount: this.selectedAccount,
    };
  }



  public handlePointsChangeEvent(event: number): void {
  /*   let min_value = this.appConstant.POINTS_TRANSFER_MIN_REDEEMABLE_POINTS;
    if( this.appConstant.POINTS_TRANSFER_MIN_REDEEMABLE_POINTS % this.providers.conversion_ratio !== 0){
      const factor = this.appConstant.POINTS_TRANSFER_MIN_REDEEMABLE_POINTS / this.providers.conversion_ratio;
      min_value = (this.Math.trunc(factor) * this.providers.conversion_ratio) + this.providers.conversion_ratio;
    } */
    if (this.providers) {
      this.conversionObject.bankPoints = event;
      this.conversionObject.providerPoints =
        this.conversionObject.bankPoints / this.providers.conversion_ratio;
      this.isButtonDisabled =
        this.conversionObject.bankPoints >
          this.MAX_SATISFACTORY_POINTS ||
        this.conversionObject.bankPoints <= 0 ||
        this.conversionObject.bankPoints < this.MIN_POINTS_REDEMPTION || this.checkConversionFactor();
    }
  }

  private get isUserLoggedIn(): boolean {
    return (
      Object.keys(JSON.parse(localStorage.getItem("login_details") ?? "{}"))
        .length > 0
    );
  }

  public onTransferButtonClick(): void {
    if (!this.isUserLoggedIn) {
      this.openLoginModal();
      return;
    }
    if (this.points < this.MIN_POINTS_REDEMPTION) {
      this.toastr.error("Your points balance is less than the selected value.");
      return;
    }
    if (this.checkConversionFactor()) {
      this.customError =
        "Points can be transferred in increments of " +
        this.MIN_POINTS_REDEMPTION + " only";
        this.toastr.error(this.customError);
        return;
    }
    if(this.isPaymentUnderProcess){
      return;
    }
    this.isPaymentUnderProcess = true;
    this.ptsService
      .createTransfer({
        user_points_provider_account_id: this.selectedAccount.id,
        // user_points_provider_account_id : '49bcb142-3533-47d5-bfc1-21d4d7e02f25',
        points_provider_id: this.providerId,
        points: this.conversionObject.providerPoints,
        points_breakup: {
          reward_points: this.conversionObject.providerPoints,
          card: 0
        }
      })
      .subscribe(
        (res: any) => {
          const loginDetails = JSON.parse(
            localStorage.getItem("login_details") ?? "{}"
          );
          const finalDetails = {
            phone: loginDetails.mobile,
            email: loginDetails.email,
            first_name: loginDetails.full_name,
            product_info: "transfer",
            transfer_id: res.data.attributes.id,
            amount: parseFloat(res.data.attributes.amount),
            transactions_attributes: [
              {
                txn_type: "points",
                amount: parseFloat(res.data.attributes.amount),
              }
            ],
          };
          try {
            console.log(
              finalDetails,
              ">>>>>>>>>>>>>>>>> PaymentComponent.makePayment"
            );
            this.isResponseLoading = false;

            this.pgService.makePayment(finalDetails);
          } catch (error) {
            if (error && typeof error == "string") {
              this.toastr.error("error", error);
            }
            this.isPaymentUnderProcess = false;
          }
        },
        (err) => {
          this.toastr.error("Something went wrong, please try again later");
          this.isPaymentUnderProcess = false;
        }
      );
  }

  public ngOnDestroy(): void {
    if (this.pointsSubscription?.unsubscribe) {
      this.pointsSubscription.unsubscribe();
    }
    if (this.paymentSubscription?.unsubscribe) {
      this.paymentSubscription.unsubscribe();
    }
  }

  private isMobile() {
    return window.innerWidth < 768;
  }
  public openLoginModal(): void {
    this.dialog.open(SbicLoginComponent, {
      width: "450px",
      height: "70vh",
      disableClose: true,
    });
  }
  public back() {
    this.router.navigate(['points-transfer']);
  }

  private checkConversionFactor(): boolean {
    return (this.conversionObject.bankPoints % this.MIN_POINTS_REDEMPTION) !== 0
  }
}
